import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { navigate, Link } from 'gatsby';
import PropTypes from 'prop-types';
import Loading from '../../1TalkDesign/Loading';
import { validatePasscode } from '../../../services/user.service';
import { phoneValidationSuccess } from '../../../lib/liff';
import { getSearchParamsStrings } from 'src/utils';
import { t } from 'src/i18n/config';
import { TextField, InputContainer } from '../components/Fields';
import Button from '../components/Button';

const Description = styled.div`
  margin-top: 8px;
  color: ${({ theme }) => theme.colors.gray[500]};
  font-size: ${({ theme }) => theme.font_size.sm};
  line-height: 17.5px;
  letter-spacing: 2.5%;
`;

const ResendContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.colors.gray[500]};
  gap: 8px;
  a {
    color: ${({ theme }) => theme.colors.primaryColor[600]};
    font-size: ${({ theme }) => theme.font_size.base};
  }
`;

const Validation = ({ phone, user }) => {
  const [passcode, setPasscode] = useState('');
  const [loading, setLoading] = useState(false);
  const timeRun = 120;
  const [countdown, setCountdown] = useState(timeRun);
  useEffect(() => {
    let timeRunTemp = timeRun;
    let interval = setInterval(() => {
      timeRunTemp -= 1;
      setCountdown(timeRunTemp);
      if (timeRunTemp === 0) {
        clearInterval(interval);
        setCountdown(0);
      }
    }, 1000);
    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, []);

  const handleClickConfirmValidation = async () => {
    try {
      setLoading(true);
      await validatePasscode({ phone, passcode });
      const trimmedPhone = phone.replace(/\+886/g, '0');
      phoneValidationSuccess(trimmedPhone);
      if (user.patients.length === 0) {
        const searchParams = getSearchParamsStrings();
        await navigate(`/users/create${searchParams}`, {
          replace: true,
          state: { user: { phone } },
        });
        return;
      }
      await navigate('/users/create/success?from=create', { replace: true });
    } catch (error) {
      alert(error.message);
      setLoading(false);
    }
  };

  const handlePasscodeInput = (event) => {
    setPasscode(event.currentTarget.value);
  };

  return (
    <InputContainer>
      <TextField
        onChange={handlePasscodeInput}
        placeholder={t('enterReceivedSMSCode')}
        inputMode="numeric"
        maxLength={6}
      />
      <Description>
        {t('verificationCodeSentTo')} {phone} {t('mayTakeAWhile')}
      </Description>
      <Button disabled={loading} onClick={handleClickConfirmValidation}>
        {loading ? <Loading /> : t('confirmCode')}
      </Button>
      <ResendContainer>
        {t('notReceivedCode')}
        <Link to="/users/binding/input">
          {t('retry')} ( {countdown} )
        </Link>
      </ResendContainer>
    </InputContainer>
  );
};

Validation.propTypes = {
  phone: PropTypes.string,
  user: PropTypes.shape({
    id: PropTypes.string,
  }),
};

export default Validation;
